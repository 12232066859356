import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { PaymentForm, CreditCard } from "react-square-web-payments-sdk";
import ContactStyle from "../Contact/Contact.module.css";

// import { useContactContext } from "Components/Context/ContactContext";
import { Form } from "react-bootstrap";

const MyPaymentForm = () => {
  const navigate = useNavigate();
  const amount = 27.0; // Amount in dollars
  // const { type, setType, email, sendEmailHandler } = useContactContext();
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const emailContact = ContactStyle.emailContact;

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleNameChange = (event) => {
    setFullName(event.target.value);
  };

  return (
    <>
      <h2>Registration Payment</h2>
      <p>Amount: ${amount.toFixed(2)}</p>

      <Form>
        <Form.Control
          className={emailContact}
          name="email"
          value={fullName}
          onChange={handleNameChange}
          type="text"
          rows={3}
          placeholder="NAME"
          required
        />
        <Form.Control
          className={emailContact}
          name="email"
          value={email}
          onChange={handleEmailChange}
          type="email"
          rows={3}
          placeholder="EMAIL"
          required
        />
      </Form>

      <PaymentForm
        applicationId="sq0idp-KQ8gyOa1SfCh-mL-wWbngA"
        locationId="L80KFT030HD0V"
        cardTokenizeResponseReceived={async (token, buyer) => {
          try {
            const response = await fetch(
              "https://acbcwebsite.onrender.com/api/pay",
              // "http://localhost:10000/api/pay",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  sourceId: token.token,
                  email: email,
                  fullName: fullName,
                  payment: amount,
                }),
              }
            );

            if (response.ok) {
              const data = await response.json();
              alert("Payment successful. Thank you!");
              console.log(JSON.stringify(data, null, 2));
              navigate("/register-form"); // Navigate to the register page
            } else {
              console.error("Server response:", await response.text());
              alert("Payment failed. Please try again.");
            }
          } catch (error) {
            console.error("Error processing payment:", error);
            alert("An error occurred. Please try again.");
          }
        }}
      >
        <div style={{ marginBottom: "20px" }}>
          <CreditCard />
        </div>
      </PaymentForm>
    </>
  );
};

export default MyPaymentForm;
